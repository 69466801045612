import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { EXPANDED_SIDEBAR_WIDTH_PX, SHRINKED_SIDEBAR_WIDTH_PX } from './types';

export interface SidebarState {
  width: number;
  display: boolean;
}

const initialState: SidebarState = {
  width: EXPANDED_SIDEBAR_WIDTH_PX,
  display: true
};


export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    expand: (state) => {
      state.width = EXPANDED_SIDEBAR_WIDTH_PX;
    },
    shrink: (state) => {
      state.width = SHRINKED_SIDEBAR_WIDTH_PX;
    },
    setWidth: (state, action: PayloadAction<number>) => {
      state.width += action.payload;
      if(state.width < SHRINKED_SIDEBAR_WIDTH_PX) {
        state.display = false;
      } else {
        state.display = true;
      }
      // document.documentElement.style.setProperty('--sidebar-width', state.width + 'px');
    },
    show: (state) => {
      state.display = true;
    },
    hide: (state) => {
      state.display = false;
    },
  },
});

export const { expand, shrink, setWidth, show, hide } = sidebarSlice.actions;


export const selectSidebarWidth = (state: RootState) => state.sidebar.width;
export const selectSidebarDisplay = (state: RootState) => state.sidebar.display;
export default sidebarSlice.reducer;
