export const SHRINKED_SIDEBAR_WIDTH_PX = 50;
export const EXPANDED_SIDEBAR_WIDTH_PX = 250;
export const SIDEBAR = 'sidebar';
export const MENU_TITLE = 'menu-title';
export const MENU_ITEM = 'menu-item';
export const MENU_GROUP = 'menu-group';
export const IMAGE_TYPE = 'image';
export const TITLE_TYPE = 'title';

export interface ISidebar {
    base: 'sidebar';
    logo: SidebarLogo;
    content: (ISidebarMenuTitle|ISidebarMenuItem|ISidebarMenuGroup)[];
}

export interface ISidebarMenuTitle {
    element: 'menu-title';
    label: string;
    icon: string;
}

export interface ISidebarMenuItem {
    element: 'menu-item';
    label: string;
    tags: string[];
    icon: string;
    key: string;
    location: string;
}

export interface ISidebarMenuGroup {
    element: 'menu-group';
    label: string;
    icon: string;
    key: string;
    items: ISidebarMenuItem[];
}

export interface SidebarLogo { 
    type: 'image' | 'title';
    expanded: string;
    shrinked: string;
}

