import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { useAuthentication } from '../../hooks';

export interface UserInfo {
    name?: string | null; // username
    email?: string | null; // work email
    id?: string | null;
    picture?: string | null;
    roles?: string[] | null;
    group?: string | null;

}

export interface AuthenticationState {
    authenticated: boolean;
    userInfo: UserInfo | null;
}

const initialState: AuthenticationState = {
    authenticated: false,
    userInfo: null
};

export const initAsync = createAsyncThunk(
    'cookieAuthentication/init',
    async () => {
        const { auth, username } = useAuthentication();
        return { auth, username };
    }
);

export const cookieAuthenticationSlice = createSlice({
  name: 'cookieAuthentication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initAsync.pending, (state) => {
        state.authenticated = false;
        state.userInfo = null;
      })
      .addCase(initAsync.fulfilled, (state, action) => {
        state.authenticated = action.payload.auth;
        const userDetails: UserInfo = {
            name: action.payload.username,
            email: action.payload.username,
            id: action.payload.username,
            picture: action.payload.username
            //roles: action.payload.username
        }
        state.userInfo = userDetails;
      });
  },
});

export const selectAuthenticated = (state: RootState) => state.cookieAuthentication.authenticated;
export const selectUserInfo = (state: RootState) => state.cookieAuthentication.userInfo;

export default cookieAuthenticationSlice.reducer;
