import {  createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface UserInfo {
    name?: string;  // username
    email?: string; // work email
    id?: string;
    familyName?: string;
    givenName?: string;
    group?: string;
    picture?: string;
    phoneNumber?: string;
    manager?: string;
    roles?: string[];
}

export interface AuthenticationState {
    authenticated: boolean;
    userInfo: UserInfo | null;
}

const initialState: AuthenticationState = {
    authenticated: false,
    userInfo: null
};


export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    set: (state, action: any) => {
      state.authenticated = true;
      state.userInfo = {
        name : action?.payload?.name,
        familyName : action?.payload?.familyName,
        givenName : action?.payload?.givenName,
        group: action?.payload?.group,
        email : action?.payload?.email,
        id : action?.payload?.id,
        picture: action?.payload?.picture,
        roles: action?.payload?.roles,
        phoneNumber: action?.payload?.phoneNumber,
        manager: action?.payload?.manager
      }
    },
    unset: (state) => {
      state.authenticated = false;
      state.userInfo = null;
    },
  },
});

export const { set, unset } = authenticationSlice.actions;

export const selectAuthenticated = (state: RootState) => state.authentication.authenticated;
export const selectUserInfo = (state: RootState) => state.authentication.userInfo;

export default authenticationSlice.reducer;
