import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface BreadcrumbState {
  paths: BreadcrumbPath[];
}

export interface BreadcrumbPath {
  path: string,
  routeName: string,
  variables?: string[]
};

const initialState: BreadcrumbState = {
  paths: [ 
    {
      path: "Home",
      routeName: "Home"
    }
   ]
};

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    addPath: (state, action: PayloadAction<BreadcrumbPath>) => {
      state.paths.push(action.payload);
    },
    init: (state) => {
      state.paths = initialState.paths;
    },
    addFromInit: (state, action: PayloadAction<BreadcrumbPath[]>) => {
      state.paths = [...initialState.paths, ...action.payload];
    },
    goBack: (state) => {
      state.paths.pop();
    },
    goBackTillIndex: (state, action: PayloadAction<number>) => {
      state.paths.splice(action.payload);
    }
  },
});

export const { addPath, init, goBack, addFromInit, goBackTillIndex } = breadcrumbSlice.actions;

export const selectBreadcrumbPaths = (state: RootState) => state.breadcrumb.paths;
export default breadcrumbSlice.reducer;
