export const loadState = (key: string) => {
    try {
        const serialiazedState = localStorage.getItem(key);
        if(serialiazedState === null) {
            return undefined;
        }
        return JSON.parse(serialiazedState);
    } catch(err) {
        return undefined;
    }
};

export const saveState = (key: string, state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(key, JSON.stringify(serializedState));
    } catch(err) {
        // Ignore them but should be logged
    }
};