import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import Keycloak, { KeycloakInstance } from 'keycloak-js';

export interface UserInfo {
    name: string | undefined;
    email: string| undefined;
    id: string | number | undefined;
    picture: string | undefined;
    roles?: string[] | null;
    group?: string | null;
}

export interface IdentityProviderState {
    keycloak: KeycloakInstance | null;
    authenticated: boolean;
    userInfo: UserInfo | null;
}

const initialState: IdentityProviderState = {
    keycloak: null,
    authenticated: false,
    userInfo: null
};

export const initAsync = createAsyncThunk(
    'identityProvider/init',
    async (configFile: string) => {
        const keycloak = Keycloak(configFile);
       // const authenticated = await keycloak.init({onLoad: 'login-required'});
        // const loadedUserInfo: any  = await keycloak.loadUserInfo();
        
        const loadedUserInfo: any = {userName: "toto"};
        return { keycloak, authenticated: false, loadedUserInfo};
    }
);

export const identityProviderSlice = createSlice({
  name: 'identityProvider',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initAsync.pending, (state) => {
        state.keycloak = null;
        state.authenticated = false;
        state.userInfo = null;
      })
      .addCase(initAsync.fulfilled, (state, action) => {
        state.keycloak = action.payload.keycloak;
        state.authenticated = action.payload.authenticated;
        const userDetails: UserInfo = {
            name: action.payload.loadedUserInfo.name,
            email: action.payload.loadedUserInfo?.email,
            id: action.payload.loadedUserInfo?.sub,
            picture: action.payload.loadedUserInfo?.picture
        }
        state.userInfo = userDetails;
      });
  },
});

export const selectIdentityProviderInstance = (state: RootState) => state.identityProvider.keycloak;
export const selectAuthenticated = (state: RootState) => state.identityProvider.authenticated;
export const selectUserInfo = (state: RootState) => state.identityProvider.userInfo;

export default identityProviderSlice.reducer;
