import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from  '../../../store';

export interface TenantState {
  label: string;
  subscriptionId: string;
  timeZone: string;
  offset: string;
  description: string;
  name: string;
  environment: string;
  homeCurrency: string;
  logoFileName: string;
}

const initialState: TenantState = {
  label: "",
  subscriptionId: "",
  timeZone: "",
  offset: "+00:00",
  environment: "",
  homeCurrency: "EUR",
  logoFileName: "",
  description: "",
  name: ""
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<TenantState>) => {
      //debugger;
      state.label = action.payload.label;
      state.subscriptionId = action.payload.subscriptionId;
      state.timeZone = action.payload.timeZone;
      state.offset = action.payload.offset;
      state.environment = action.payload.environment;
      state.homeCurrency = action.payload.homeCurrency;
      state.logoFileName = action.payload.logoFileName;
      state.description = action.payload.description;
      state.name = action.payload.name;
      //state = {...state, ...action.payload};

    },
    setLogoFileName: (state, action: PayloadAction<TenantState>) => {
      state.logoFileName = action.payload.logoFileName;
    },
    unset: (state) => {
      state.label = "";
      state.subscriptionId = "";
      state.timeZone = "";
      state.offset = "+00:00";
      state.environment = "";
      state.homeCurrency = "EUR";
      state.logoFileName = "";
      state.description = "";
      state.name = "";
    }
  }
});

export const { set, setLogoFileName, unset } = tenantSlice.actions;

export const selectTenantDetails = (state: RootState) => state.tenant;
export const selectHomeCurreny = (state: RootState) => state.tenant.homeCurrency;
export const selectTenantOffset = (state: RootState) => state.tenant.offset;
export const selectLogoFileName = (state: RootState) => state.tenant.logoFileName;


export default tenantSlice.reducer;
