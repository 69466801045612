import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export interface AlertsState {
  alerts: IAlert[];
}

export interface IAlert { 
   title: string,
   hasDismissButton: boolean,
   variant: 'success' | 'warning' | 'info' | 'danger' | 'primary' | 'secondary' | 'light' | 'dark',
   message: string
}

const initialState: AlertsState = {
  alerts: []
};


export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<IAlert>) => {
      state.alerts = [...state.alerts, action.payload]
    },
    removeAlert: (state, action: PayloadAction<number>) => {
      state.alerts = state.alerts.splice(action.payload, 1);
    }
  }
});

export const { addAlert, removeAlert } = alertsSlice.actions;

export const selectAlerts = (state: RootState) => state.alerts;

export default alertsSlice.reducer;
