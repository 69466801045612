import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../domain/components/counter/counterSlice';
import tenantReducer from './features/administration/tenantSettings/tenantSlice';
import sidebarReducer from './layout/sidebar/siderbarSlice';
import breadcrumbReducer from './layout/breadcrumb/breadcrumbSlice';
import identityProviderReducer from './connection/deprecated/identityProviderSlice';
import cookieAuthenticationReducer from './connection/deprecated/cookieAuthenticationSlice';
import authenticationReducer from '../app/connection/authenticationSlice';
import alertsReducer from '../domain/components/alert/alertsSlice';
import { loadState, saveState } from '../infrastructure/localStorage';

//const preloadedState = JSON.parse(loadState('state'));
//console.log('PCHHOOOOOOOO');
//console.log(preloadedState);

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    sidebar: sidebarReducer,
    breadcrumb: breadcrumbReducer,
    identityProvider: identityProviderReducer,
    alerts: alertsReducer,
    cookieAuthentication: cookieAuthenticationReducer,
    authentication: authenticationReducer, // used for authentication (not the others)
    tenant: tenantReducer
  },
  preloadedState: loadState('state') ? JSON.parse(loadState('state')) : undefined
});

store.subscribe(() => {
  saveState('state', {
    sidebar: store.getState().sidebar //,
    //tenant: store.getState().tenant
  });
  // localStorage.setItem('sidebar', JSON.stringify(store.getState().sidebar));
}); 

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
                                                      RootState,
                                                      unknown,
                                                      Action<string>>;
