import { useState, useReducer, useEffect, useRef /*, useCallback */} from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from './store';
import axios, { Method }  from 'axios';
//import { set, unset, selectAuthenticated } from './connection/authenticationSlice';
// @import /*Keycloak ,*/ { KeycloakInstance } from 'keycloak-js';
//import { selectIdentityProviderInstance } from '../app/connection/identityProviderSlice';
import { addAlert, IAlert } from  '../domain/components/alert/alertsSlice';
import { UseFormRegister, FieldValues, UseFormSetValue, Path } from 'react-hook-form';
import { ICoreResponse } from '../domain/types/MyTypes';
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const dataFetchReducer = (state: any, action: any) => {
    switch (action.type) {
      case 'FETCH_INIT':
        return {
          ...state,
          isLoading: true,
          isError: false,
          apiError: null,
          apiStatus: null
        };
      case 'FETCH_SUCCESS':
        return {
          ...state,
          isLoading: false,
          isError: false,
          apiError: null,
          apiResponse: action.payload.responseBody,
          apiStatus: action.payload.status
        };
      case 'FETCH_FAILURE':
        return {
          ...state,
          isLoading: false,
          isError: true,
          apiError: action.payload.responseBody,
          apiResponse: null,
          apiStatus: action.payload.status
        };
      default:
        throw new Error();
    }
};

export const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useEffectDebugger = (effectHook: any, dependencies: any[], dependencyNames = []) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useEffect(effectHook, dependencies);
};

export interface IWebAPIRequestConfiguraton {
  url: string, 
  method?: Method,
  payload?: any | null | undefined, 
  triggerNow?: boolean,
  initialState?: any | null | undefined, 
  transformResponseCallback?: (data: any) => any | undefined, 
  transformErrorCallback?: (error: any) => any | undefined
}

export const useFormRef = () => {
  const submitableFormRef = useRef<any>(null);
  const submitForm = () => {
    if(submitableFormRef.current instanceof HTMLFormElement) {
      submitableFormRef.current?.dispatchEvent(new Event('submit', 
        { 
          cancelable: true, 
          bubbles: true 
        })); 
    }
  };
  const [isFormLoading, setIsFormLoading] = useState<boolean | null | undefined>(false);
  const onLoading = (isLoading?: boolean) => {
      setIsFormLoading(isLoading);
  };
  return [submitableFormRef, submitForm, isFormLoading, onLoading] as const;
}

export const useModalRef = () => {
  const modalRef = useRef<any>(null);
  const openModal = () => {
    if(modalRef && modalRef.current)
      modalRef.current.click();
  };
  return [modalRef, openModal] as const;
}

export const useMonacoEditorAsFieldForm = <TFieldValues extends FieldValues>(
  register: UseFormRegister<TFieldValues>,
  name: any, //Path<TFieldValues>,
  //defaultValue: string,
  setValue: UseFormSetValue<TFieldValues>
): ((value: string) => void) => {
  useEffect(() => {
      register(name);
  }, [register, name]);

  const handleChange = (value: any /*string*/): void => {
      setValue(name, value);
  };

  return handleChange;
};

export const useWebAPI = (configuration: IWebAPIRequestConfiguraton) => {
  
  const {
    url, 
    method = 'get', 
    payload = null, 
    triggerNow = false, 
    initialState = null, 
    transformResponseCallback = null, 
    transformErrorCallback = null 
  } = configuration;

  const [requestUrl, setRequestUrl] = useState<string>(url);   
  const [requestBody, setRequestBody] = useState<any>(payload); 
  const [triggerRequest, setTriggerRequest] = useState<boolean>(triggerNow);   
  const [reTrigger, setReTrigger] = useState(""); 

  const refresh = () => setReTrigger((Math.random() + 1).toString(36).substring(2).toUpperCase());

  /*useEffectDebugger(() => {
    console.debug("trigger request", {triggerRequest});
    console.debug(triggerRequest);
  }, [triggerRequest]); */

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    apiResponse: initialState,
    apiStatus: null,
    apiError: null
  });

  useEffect(() => {
    let didCancel = false;
    if(url === undefined || !triggerRequest) {
      return;
    }
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const result = await axios({  
          url: requestUrl, 
          method: method,
          data: requestBody 
        });
        if (!didCancel) {
          dispatch({  
            type: 'FETCH_SUCCESS', 
            payload: {  
              responseBody: transformResponseCallback 
                ? transformResponseCallback(result?.data) 
                : result?.data,
            status: result?.status }
          });
        }
        return;
      } catch (error: any) {
        if (!didCancel) {
          const { response = {} } = error;
          const { data = '', status = 500 } = response;
          dispatch({  
            type: 'FETCH_FAILURE', 
            payload: { 
              responseBody: transformErrorCallback 
                ? transformErrorCallback(error) 
                : data,
              status: status 
            }
          });
        }
      }
    };

    fetchData();
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggerRequest, 
    method, 
    requestUrl, 
    requestBody, 
    JSON.stringify(transformResponseCallback), 
    JSON.stringify(transformErrorCallback), 
    reTrigger
  ]);

  return [
    state, 
    setTriggerRequest, 
    setRequestUrl, 
    setRequestBody, 
    refresh, 
    requestBody, 
    requestUrl, 
    triggerRequest
  ];
}

export const useNotification = (
  predicate: boolean, 
  title: string, 
  variant: string = "info", 
  message: string, 
  callback?: (p?: any) => any 
) => {
  const dispatch = useAppDispatch();
  if (predicate) {
    const notification = {  
      title, 
      hasDismissButton: true, 
      variant,
      message
    };
    dispatch(addAlert(notification as IAlert)); 
    if(callback) 
      callback();
  } 
}

/*function useObservable(observable$, initialValue) {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const subscription = observable$.subscribe({
      next: (v) => {
        setValue(v);
        setLoading(false);
      },
      error: (e) => {
        console.error(e);
        setLoading(false);
      },
    });
    return () => subscription.unsubscribe();
  }, [observable$]);

  return [value, loading];
}*/

/*export const useWebAPIResponseNotification = (successStatus: number,
                                              status: number,
                                              isLoading: boolean, 
                                              isError: boolean, 
                                              successTitle?: string,
                                              successMessage?: string,
                                              failureTitle?: string,
                                              failureMessage?: string,
                                              onSuccess?: (p?: any) => any,
                                              onFailure?: (p?: any) => any) => {
                                          
    useEffect(() => {
      if( status === successStatus 
          && !isLoading 
          && !isError) {
          const notification = {  title: "Title Deleted", 
                                hasDismissButton: true, 
                                variant: "success",
                                message: `Title ${Name} was deleted succesfully`};
          dispatch(addAlert(notification as IAlert)); 
          if(onSuccess) 
              onSuccess();

      }
      else if( status !== successStatus 
                  && !isLoading 
                  && isError) {
          const notification = {  title: "Title Deletion Error", 
                                  hasDismissButton: true, 
                                  variant: "danger",
                                  message: `Error when trying to delete title : ${JSON.stringify(deleteTitleError,null,4)}`};
          dispatch(addAlert(notification as IAlert)); 
          if(onFailure) 
              onFailure();
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteTitleError, deleteTitleStatus, dispatch, isDeleteTitleError, isDeleteTitleLoading]);
};*/




/*export const useOutsideClick = () => {

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current?.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);
}*/

export const useRequest = ( 
  url: string | undefined, // todo put everything in a single object
  method: Method = 'get', 
  payload: any | null | undefined = null, 
  triggerNow: boolean = false,
  initialState: any | null | undefined,
  transformResponseCallback?: (data: any) => any | undefined, 
  transformErrorCallback?: (error: any) => any | undefined) => {

  const [requestUrl, setRequestUrl] = useState(url);   
  const [requestBody, setRequestBody] = useState(payload); 
  const [triggerRequest, setTriggerRequest] = useState(triggerNow);   
  const [reTrigger, setReTrigger] = useState(""); 

  const refresh = () => setReTrigger((Math.random() + 1).toString(36).substring(2).toUpperCase());

  const [state, dispatch] = useReducer(
    dataFetchReducer, 
    {
      isLoading: false,
      isError: false,
      apiResponse: initialState,
      apiStatus: null,
      apiError: null
    }
  );

  useEffect(() => {
    let didCancel = false;

    if(url === undefined || !triggerRequest) {
      return;
    }

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const result = await axios({  
          url: requestUrl, 
          method: method,
          data: requestBody 
        });
        if (!didCancel) {
          dispatch({ 
            type: 'FETCH_SUCCESS', 
            payload: { 
              responseBody: transformResponseCallback 
                ? transformResponseCallback(result?.data) 
                : result?.data,
              status: result?.status
            }
          });
        }
      } catch (error: any) {
        if (!didCancel) {
          dispatch({ 
            type: 'FETCH_FAILURE', 
            payload: { 
              responseBody: transformErrorCallback 
                ? transformErrorCallback(error) 
                : error.response.data,
              status: error.response.status 
            }
          });
        }
      }
    };

    fetchData();
      return () => {
          didCancel = true;
      };
  }, [
    triggerRequest, 
    url, 
    method, 
    requestUrl, 
    requestBody, 
    transformResponseCallback, 
    transformErrorCallback, 
    reTrigger
  ]);

  return [
    state, 
    setTriggerRequest, 
    setRequestUrl, 
    setRequestBody, 
    refresh
  ];
}

export const useRefForm = () => {
  const submitableFormRef = useRef<any>(null);
  const submitForm = () => {
    submitableFormRef.current
      ?.dispatchEvent(new Event(
          'submit', 
          { 
            cancelable: true, 
            bubbles: true 
          }
        )); 
  };

  return [submitableFormRef, submitForm];
}

export const useWebAPIResponseNotification = (
  successStatus: number,
  apiStatus: number,
  isLoading: boolean,
  isError: boolean,
  successTitle?: string,
  successMessage?: string,
  apiError?: ICoreResponse<any> | any | null | undefined,
  failureTitle?: string,
  failureMessage?: string,
  onSuccess?: (p?: any) => any,
  onFailure?: (p?: any) => any,
  triggerRequest?: (p?: any) => any,
) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
      if(apiStatus === successStatus && !isLoading && !isError) {
        const notification = {  
          title: successTitle, 
          hasDismissButton: true, 
          variant: "success",
          message: successMessage
        };
        dispatch(addAlert(notification as IAlert)); 
        if(onSuccess) 
          onSuccess();
        if(triggerRequest)
          triggerRequest(() => false);
      } 
      else if (apiStatus !== successStatus && !isLoading && isError) {
        let title = failureTitle;
        let details = failureMessage;
        if(apiError) {
          title = apiError?.title ?? title;
          details = apiError?.detail ?? details;
        }
        const notification = {  
          title,
          hasDismissButton: true, 
          variant: "danger",
          message: details
        };
        dispatch(addAlert(notification as IAlert)); 
        if(onFailure) 
          onFailure();
        if(triggerRequest)
          triggerRequest(() => false);
      }
  }, [apiStatus, isError, isLoading, successMessage, successStatus, successTitle, failureTitle, failureMessage]);
}

export const useAPIRequest = (
  initialUrl: string, 
  initialData: any, 
  transformResponseCallback?: (data: any) => void, 
  transformErrorCallback?: (error: any) => void
) => {
    const [url, setUrl] = useState(initialUrl);   
    const [state, dispatch] = useReducer(
      dataFetchReducer, 
      {
        isLoading: false,
        isError: false,
        apiResponse: initialData,
        apiStatus: null
      }
    );
   
    useEffect(() => {
      let didCancel = false;
      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });
        if(url) {
          try {
            const result = await axios(url);
            if (!didCancel) {
              dispatch({ 
                type: 'FETCH_SUCCESS',
                payload: { 
                  responseBody: transformResponseCallback 
                    ? transformResponseCallback(result?.data) 
                    : result?.data,
                  status: result?.status 
                }
              });
            }
          } catch (error: any) {
            if (!didCancel) {
              dispatch({ 
                type: 'FETCH_FAILURE', 
                payload: {  
                  responseBody: transformErrorCallback 
                    ? transformErrorCallback(error) 
                    : error?.response?.data,
                  status: error?.response?.status 
                }
              });
            }
          }
        }
      };

      fetchData();
      return () => {
          didCancel = true;
      };
    }, [url, transformResponseCallback, transformErrorCallback]);
   
    return [state, setUrl];
};

export const useForm = (initialValues: any) => {
  const [values, setValues] = useState(initialValues);
  return [
    values, 
    setValues,
    (e: any) => {
      setValues({
        ...values,
        [e.target.name]: (e.target.type === "checkbox") 
          ? e.target.checked 
          : e.target.value
      });
    }
  ];
};

export const useAuthentication = () => {
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [username, setUsername] = useState<string | null | undefined>(null);
    //const [roles, setRoles] = useState<string[]>([]);
    //const dispatch = useAppDispatch();
    const [{ apiResponse }] = useAPIRequest('https://localhost:5001/auth/connexion', null);
    console.log("API RESPONSE");
    console.log(apiResponse);
    useEffect(() => {
      if(apiResponse) {
        setAuthenticated(true);
        setUsername(apiResponse.data);
        //dispatch(set(apiResponse.data));
        //setUsername(apiResponse.data);
      } /*else {
        dispatch(unset());
      }*/
      // TODO : fixthat shit
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { auth: authenticated /*useAppSelector(selectAuthenticated)*/, username /*, roles */}
}

export const useSingleAndDoubleClick = (actionSimpleClick : (e?: any) => void | undefined, actionDoubleClick : (e?: any) => void | undefined, delay: number = 250) => {
  const [click, setClick] = useState(0);

  useEffect(() => {
      const timer = setTimeout(() => {
          // simple click
          if (click === 1 && actionSimpleClick) actionSimpleClick();
          setClick(0);
      }, delay);

      // the duration between this click and the previous one
      // is less than the value of delay = double-click
      if (click === 2 && actionDoubleClick) actionDoubleClick();

      return () => clearTimeout(timer);
      
  }, [click, actionSimpleClick, actionDoubleClick, delay]);

  return () => setClick(prev => prev + 1);
}

/*export const useKeycloak = () => {
    const [keycloak, setKeycloak] = useState<KeycloakInstance | null>(null);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
  
    useEffect(() => {
      if(!authenticated) {
        const keycloak = Keycloak('/keycloak.json');
        keycloak.init({onLoad: 'login-required'})
        .then(authenticated => {
            setKeycloak(keycloak);
            setAuthenticated(authenticated);
        });
      }
    }, [authenticated]);
  
    return { keycloak, authenticated };
}*/


/*export const useUserInfo = () => {
    //const { keycloak } = useKeycloak();
    const keycloak = useAppSelector(selectIdentityProviderInstance);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

    (keycloak as KeycloakInstance).loadUserInfo().then((userInfo: any) => {
        console.log('UUSER');
        console.log(userInfo);
        const userDetails: UserInfo = {
            name: userInfo?.name,
            email: userInfo?.email,
            id: userInfo?.sub,
            picture: userInfo?.picture
        }
        setUserInfo(userDetails);
    });
  
    return userInfo;
}*/
