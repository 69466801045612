import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { set, unset } from './app/connection/authenticationSlice';
import { useWebAPI, useAppDispatch } from './app/hooks';
import './i18n'; // Path to your i18n.js file

const MyLayout = React.lazy(() => import('./app/layout/MyLayout'));

function Loading() {
  return(
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
}

function ServerError() {
  return (
    <div>
      Could not load authentication server
    </div>
  );
}

export function NotFoundPage() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
      <p><a href="/">Go to Homepage</a></p>
    </div>
  );
}

const ProtectedRoute = ({ 
  component: Comp, 
  loggedIn, 
  errorStatus, 
  path, 
  ...rest 
}: any) => {
  if (!loggedIn) {
    if (errorStatus !== 404 && errorStatus !== 500) {
      window.location.href = window.location.origin + '/auth/login';
      return null;
    } 
    else {
      return <ServerError />;
    }
  }

  return <Route 
    path={path} 
    {...rest} 
    render={(props) => 
      {
        return (<Comp {...props} />)} 
      }
    />;
};

function App() {

  const [{  
    apiResponse : getCurrentUserResponse, 
    isLoading : isGetCurrentUserLoading, 
    isError: isGetCurrentUserError,
    apiStatus: getCurrentUserStatus,
    apiError: getCurrentUserError
  }] = useWebAPI({
    url: `/api/users/me`,
    method: 'GET', 
    triggerNow: true
  });

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [startingRoute] = useState<String>("/");

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if(getCurrentUserResponse 
      && getCurrentUserResponse.email 
      && getCurrentUserResponse.email !== '' 
      && !isGetCurrentUserLoading 
      && !authenticated 
      && !isGetCurrentUserError
      && getCurrentUserStatus === 200) {
      setAuthenticated(true);
      dispatch(set(getCurrentUserResponse));
    }
    else if(!isGetCurrentUserLoading 
      && isGetCurrentUserError 
      && getCurrentUserStatus === 404) {
      setAuthenticated(false);
      dispatch(unset());
    }  
    else if(!isGetCurrentUserLoading 
      && isGetCurrentUserError 
      && getCurrentUserStatus === 401) {
      setAuthenticated(false);
      dispatch(unset());
      window.location.href = window.location.origin + '/auth/login';
    }  
    else if(!isGetCurrentUserLoading 
      && isGetCurrentUserError 
      && getCurrentUserStatus !== 200
      && getCurrentUserStatus !== 500) {
      setAuthenticated(false);
      dispatch(unset());
      // TODO : SHOW A PAGE (Error happened)
    }  
    else if(!isGetCurrentUserLoading 
      && isGetCurrentUserError 
      && getCurrentUserStatus === 500) {
      setAuthenticated(false);
      dispatch(unset());
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ 
      JSON.stringify(getCurrentUserError), 
      JSON.stringify(getCurrentUserResponse), 
      getCurrentUserStatus, 
      isGetCurrentUserLoading, 
      isGetCurrentUserError, 
      authenticated
    ]);

    return (<>
      { authenticated  
        ? <BrowserRouter>
          <Suspense fallback={<Loading/>}>
            <Switch>
              <ProtectedRoute 
                path={startingRoute} 
                loggedIn={authenticated} 
                errorStatus={getCurrentUserStatus} 
                component={MyLayout} />
                <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </BrowserRouter>
        : <Loading />
      }
    </>);
}

export default App;
